$(function() {
    $('.image_zoom').ezPlus({
        cursor: 'pointer',
        imageCrossfade: true,
        lensSize: Number($('.user_settings[data-zoom_lens_size]')[0].getAttribute('data-zoom_lens_size')),
        lensShape:"round",//can be "round"
        zoomType:"lens",
        containLensZoom:true,
        scrollZoom: true,
        constrainType: "width", //width or height
        constrainSize: 400
    });
})
