// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
require("@rails/activestorage").start()
require('turbolinks')
require("jquery") // Don't really need to require this...
//require("jquery_ujs")
// require("jquery-ui")
require("micromodal/dist/micromodal")
require("ez-plus")
require("bootstrap/js/dist/dropdown")
require("bootstrap/js/dist/modal")
require("bootstrap-table")
require("@nathanvda/cocoon")
//require("local-time")
import "stylesheets/application"
import '../scripts/sort_select'
import './zoom'
import '../scripts/images'
import '../scripts/wait_cursor'
import '../scripts/request_images'
import '../scripts/item_card'
// import './micromodal.js'

document.addEventListener("turbolinks:load", function() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
    })
})


