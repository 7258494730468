$(document).ready(function() {
    let brand_id = $('#item_brand_id')
    let selectedBrand = document.getElementById('item_brand_id').value;
    let brands = brand_id.html();
    let category, options;
    category = $('#item_category_id :selected').text();
    options = $(brands).filter("optgroup[label='" + category + "']");
    brand_id.empty().append( options );
    selectElement('item_brand_id', selectedBrand)

    $('#item_category_id').change(function() {
        let category, options;
        category = $('#item_category_id :selected').text();
        options = $(brands).filter("optgroup[label='" + category + "']").html();
        if (options) {
            brand_id.empty().append( options );
            document.getElementById('item_brand_id').selectedIndex = 1;
        } else {
            brand_id.empty();
        }
    });
});

function selectElement(id, valueToSelect) {
    let element = document.getElementById(id);
    element.value = valueToSelect;
}

