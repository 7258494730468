$(document).ready(function () {
    $("input[type='checkbox']").change(function () {
        const imageID = this.id.replace('_on_coa', '');
        const pill = document.getElementById(imageID);
        const maxAllowed = 4;
        const cnt = $("input[type='checkbox']:checked").length;
        if (cnt > maxAllowed)
        {
            $(this).prop("checked", "");
            alert('Select a maximum of ' + maxAllowed + ' Images!');
        }
        if(this.checked) {
            pill.classList.remove('badge-secondary')
            pill.classList.add('badge-primary')
        } else {
            pill.classList.add('badge-secondary')
            pill.classList.remove('badge-primary')
        }
    });
});