$(document).ready(function () {
// get handle of target element
const request_image_list = document.getElementById('new_image_request');
let button = document.getElementById("submit_button");
button.disabled = true;
// callback for mutation observer
const logMutations = function(mutations, observer) {
    if($(".nested-fields").length === 0){
        button.disabled = true;
    } else {
        button.disabled = false;
    }
}

// create an observer instance
const observer = new MutationObserver(logMutations);

// start observing target element
observer.observe(request_image_list, { childList: true });

});